
import { Action } from "vuex-class";
import { Component, Ref, Vue } from "vue-property-decorator";
import { FormBase, FormError, FormSubmit } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { bus } from "@/main";

interface DeleteMandateModalOptions {
    mandate_id: number;
    type?: "clear" | "reset";
    callback?: (mandate_id: number) => void;
}

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormError,
        FormSubmit,
    },
})
export default class DeleteMandateModal extends Vue {
    @Action("mandate/delete") deleteMandate!: (id: number) => void;

    @Ref() form!: FormClass;

    show = false;

    mandate_id: number | false = false;

    type: "clear" | "reset" = "clear";

    errorResponse: ErrorResponse = {
        status: 0,
        errors: [],
    };

    options: DeleteMandateModalOptions = {
        mandate_id: NaN,
    };

    mounted() {
        bus.$off("show-clear-mandate");
        bus.$on("show-clear-mandate", (options: DeleteMandateModalOptions) => {
            this.show = true;
            this.options = { ...this.options, ...options };
            this.type = options.type ?? "clear";
        });
    }

    async submit(form: FormClass) {
        try {
            if (!this.options.mandate_id) {
                return;
            }

            await this.deleteMandate(this.options.mandate_id);

            if (this.options.callback && typeof this.options.callback === "function") {
                this.options.callback(this.options.mandate_id);
            }

            this.handleClose();
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
